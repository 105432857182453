import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//firebase
import firebase from "@/utils/firebaseInit"
import firebaseTimeStamp from "firebase"
import {dbCollections} from "@/utils/firebaseCollection"
import { getCollectionDataTypesense, updateTypesenseCollection } from '../../../utils/Typesense/queries'
const db = firebase.firestore()
const months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
const allfieldAndCourseData = [];
export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'no', sortable: false },
    { key: 'field_name', sortable: true },
    { key: 'course_name', sortable: true },
    { key: 'mode_of_application', sortable: true },
    { key: 'intake', sortable: false },
    { key: 'application_deadline', sortable: false },
    { key: 'german_language', sortable: true },
    { key: 'exams', sortable: false },
    { key: 'band_scores', sortable: false },
    { key: 'actions' }
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(null)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const isSpinner = ref(null)
  const isFetching = ref(null)
  const sortContect = ref(null)

  const collegeDocId = ref(null)
  const coursesTableList = ref(null)

  const filterCountry = ref(null)
  const filterState = ref(null)
  const filterCity = ref(null)
  const fetchedData = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => { 
    refUserListTable.value.refresh()
    // console.log("Refetch",searchQuery.value,collegeDocId.value)
  }

  // function getdata(data,callback){
  //   db.collection(dbCollections.FIELDANDCOURSE).doc(data.fieldName.id).get().then(doc=>{
  //         if(doc.exists)
  //         {
  //          let result = doc.data(); 
  //         //  console.log(data.courseName.id,'??',doc.data())
  //          if(doc.data().courses.length > 0){
  //           let getcourseData = doc.data().courses.findIndex(valls=>{
  //             return data.courseName.id == valls.id
  //           })
           
  //           if(getcourseData > -1){
  //             result.courseData =  doc.data().courses[getcourseData];
  //           }else{
  //             // console.log(getcourseData,"getcourseData")
  //             result.courseData = '';
  //           }
  //          } 
  //           callback(result)
  //         }else{
  //           callback(null)
  //         }
  //   })
  // }
  const getfieldAndcourseData = (callback) => {
    db.collection(dbCollections.FIELDANDCOURSE).onSnapshot((snapshot) => {
      allfieldAndCourseData = [];
      if (!snapshot.empty) {
        // console.log(snapshot,"snapshot")
        snapshot.forEach((doc) => {
          allfieldAndCourseData.push({id: doc.id, ...doc.data()})        
        });
        // console.log(allfieldAndCourseData,"allfieldAndCourseDatafhdgjgh")
        callback(allfieldAndCourseData)
      }else{
        allfieldAndCourseData = [] 
        callback(allfieldAndCourseData)
      }
    })        
  }
  var usersList = []
  watch([perPage], () => {
    console.log(currentPage.value,perPage.value)
    fetchCourseFromTypesense(true,0,()=>{refetchData()})
  })
  onMounted(()=>{
    fetchCourseFromTypesense(true,0,()=>{refetchData()})
  })
  const fetchCourseFromTypesense = (resetResult = true,limit=0,callback) => {
    try {
      if(resetResult){
        isFetching.value = true;
        usersList = [];
      }
      console.log('fetchCourseFromTypesense');
      let searchParameters = {
        collectionName: `${dbCollections.COURSES}`,
        search: {
            q:"*",
            filter_by: `collegeId:=${collegeDocId.value}`,
            // "per_page": 10,
            page: currentPage.value,
            per_page: perPage.value,
        }
      }
      if(limit !== 0) {
        sortContect.value = null;
        isSortDirDesc.value = null;
        sortBy.value = '';
        searchParameters.search.per_page = limit;
      }
      if (sortContect.value) {
        searchParameters.search.sort_by = `${(sortContect.value.sortBy && sortContect.value.sortBy !== '') ? `${sortContect.value.sortBy}: ${sortContect.value.sortDesc == false ? 'asc' : 'desc'}` : ''}`;
      }
        getCollectionDataTypesense(searchParameters).then((result)=>{
            if(result.hits.length) {
              totalUsers.value = result.found
                const arrayData = result.hits.map(x => {
                    const row = x.document;

                    return {
                      'field_name':row.fieldName,
                      'id':row.id,
                      'language':null,
                      'remark':row.remark,
                      'course_name':row.courseName,
                      'mode_of_application':row.modeOfApplication,
                      'intake':row.intake,
                      'application_deadline':row.applicationDeadline,
                      'german_language':row.germanLanguage,
                      'exams':row.exams,
                      'band_scores':row.exams,

                      'applicationFees':row.applicationFees||'',
                      'tutionFees':row.tutionFees||'',
                      'courseUniqueId':row.courseUniqueId||'',
                      'isDeleted':row.isDeleted ||false
                    };
                });
                usersList = arrayData
                fetchedData.value = arrayData;
                callback(arrayData)
                isFetching.value = false;
            } else {
              callback([])
              isFetching.value = false;
            }
        }).catch((error) => {
          isFetching.value = false;
          callback([])
          console.error(error)
        })
    } catch (error) {
      callback([])
      console.error(error)
    }
  }
  const handlePageChange = async(newPage) => {
    console.log(newPage,'NEWPAGE');
      currentPage.value = newPage;
    
      await fetchCourseFromTypesense(true,0,()=>{refetchData()})
  }
  const handleSortChange = (context) => {
    let sortFieldObject = {
        college_name : "",
        university_name : "",
        field_name : "fieldName.name",
        course_name : "courseName.title",
        mode_of_application : "modeOfApplication", 
        dispIntake : "", 
        applicationFee : "", 
        tutionFee : "",
        dispIntakeDeadline : "", 
        german_language : "germanLanguage", 
        examsScore : "", 
        remark : ""
    }
    const {sortBy,sortDesc} = context
    sortContect.value = {sortBy:sortFieldObject[sortBy],sortDesc};
    currentPage.value = 1;
    fetchCourseFromTypesense(true,0,()=>{refetchData()})
  }

  var users = []
  const fetchUsers = (ctx, callback) => {
    users = []
    // console.log("get List")
    isSpinner.value = true
    if(usersList.length==0)
    {
      db
        .collection(dbCollections.COLLEGES)
        .doc(collegeDocId.value)
        .get()
        .then(doc=>{
          if(doc.exists && !doc.data().isDeleted)
          {
            var startIndex = (currentPage.value-1)*perPage.value+1
            var endIndex = startIndex+perPage.value-1
            var index = 1
            var dataIndex = 1
            // console.log(startIndex,endIndex)
            if(doc.data().courses.length==0)
            {
              totalUsers.value = usersList.length
              isSpinner.value = false
              callback(users);
              return;
            }
            
            //get field and course data in collection JYOTI YADAV - [20-12-21]
            if(doc.data().courses.length > 0){            
            let fileArry = doc.data().courses;
            let count = 0;
            const uploadFileFunction = (row) => {
               if(count >= fileArry.length){
                totalUsers.value = usersList.length
                isSpinner.value = false
                coursesTableList.value=usersList
                callback(users)
                return;
               }else{                
                  // getdata(row,(saveRes)=>{
                    // if(saveRes !== null){
                    // console.log(saveRes,"?saveRes?",row)
                    let fixEditData = row;
                    // console.log("SaveRes",saveRes)
                    usersList.push(fixEditData) 

                    usersList[usersList.length-1].mode_of_application=row.modeOfApplication
                    usersList[usersList.length-1].course_name=row.courseName.title
                    usersList[usersList.length-1].field_name=row.fieldName
                    usersList[usersList.length-1].german_language=row.germanLanguage
                    usersList[usersList.length-1].application_deadline=row.applicationDeadline

                    usersList[usersList.length-1].band_scores=row.exams
                    usersList[usersList.length-1].exams=row.exams
                    usersList[usersList.length-1].id=row.id

                    let fieldObj =row.fieldName;
                    if(dataIndex>=startIndex && dataIndex<=endIndex)
                      { 
                        users.push({
                          'field_name':fieldObj,
                          'id':row.id,
                          'language':null,
                          'remark':row.remark,
                          'course_name':row.courseName,
                          'mode_of_application':row.modeOfApplication,
                          'intake':row.intake,
                          'application_deadline':row.applicationDeadline,
                          'german_language':row.germanLanguage,
                          'exams':row.exams,
                          'band_scores':row.exams,
    
                          'applicationFees':row.applicationFees||'',
                          'tutionFees':row.tutionFees||'',
                          'courseUniqueId':row.courseUniqueId||'',
                          'isDeleted':row.isDeleted ||false
                        })  
                        dataIndex++;
                      }
                  // }
                  count += 1;
                  uploadFileFunction(fileArry[count]);
                  // }); 
               }             
            }
            uploadFileFunction(fileArry[count]);
          }
          
          }
          // if(doc.exists && !doc.data().isDeleted) //SHIV JOSHI CODE
          // {
          //   var startIndex = (currentPage.value-1)*perPage.value+1
          //   var endIndex = startIndex+perPage.value-1
          //   var index = 1
          //   var dataIndex = 1
          //   console.log(startIndex,endIndex)
          //   if(doc.data().courses.length==0)
          //   {
          //     totalUsers.value = usersList.length
          //     isSpinner.value = false
          //     callback(users);
          //     return;
          //   }
          //   doc.data().courses.forEach(data=>{

          //     //Turn isDeleted Flag = false
          //     // db
          //     //   .collection(dbCollections.FIELDANDCOURSE)
          //     //   .doc(doc.id)
          //     //   .update({
          //     //     isDeleted:false
          //     //   })
          //     //   .catch(error => console.log(error))
          //    console.log(data.fieldName.id,"JYOTI YADAV")   
          //     usersList.push(data)

          //     if(dataIndex>=startIndex && dataIndex<=endIndex)
          //     {
          //       users.push({
          //         'field_name':data.fieldName,
          //         'language':data.language,
          //         'remark':data.remark,
          //         'course_name':data.courseName,
          //         'mode_of_application':data.modeOfApplication,
          //         'intake':data.intake,
          //         'application_deadline':data.applicationDeadline,
          //         'german_language':data.germanLanguage,
          //         'exams':data.exams,
          //         'band_scores':data.exams,
          //       })
          //       dataIndex++;
          //     }
          //     if(index==doc.data().courses.length)
          //     {
          //       totalUsers.value = usersList.length
          //       isSpinner.value = false
          //       coursesTableList.value=usersList
          //       callback(users)
          //     }
          //     index++;
          //   })
          // }
          else
          {
            totalUsers.value = doc.hits.length
            isSpinner.value = false
            coursesTableList.value=usersList
            callback(users)
          }
        })
        .catch(error => {
          isSpinner.value = false
          console.log(error)
        })
    }
    else
    {
      // console.log("Already has list data")
      var startIndex = (currentPage.value-1)*perPage.value+1
      var endIndex = startIndex+perPage.value-1
      var index = 1
      // var dataIndex = 1
      // console.log(startIndex,endIndex)
      if(isSortDirDesc.value==false)
      {
        if(sortBy.value == 'application_deadline') {
          usersList.sort((a, b) => (new Date(a[sortBy.value][0].date).getTime() > new Date(b[sortBy.value][0].date).getTime() ? 1 : -1))
        } else if(sortBy.value == 'band_scores') {
          usersList.sort((a, b) => (a[sortBy.value][0].score > b[sortBy.value][0].score ? 1 : -1))
        } else {
          usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? 1 : -1))
        }
      }
      else if(isSortDirDesc.value==true)
      {
        if(sortBy.value == 'application_deadline') {
          usersList.sort((a, b) => (new Date(a[sortBy.value][0].date).getTime() > new Date(b[sortBy.value][0].date).getTime() ? -1 : 1))
        } else if(sortBy.value == 'band_scores') {
          usersList.sort((a, b) => (a[sortBy.value][0].score > b[sortBy.value][0].score ? -1 : 1))
        }
        else {
          usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? -1 : 1))
        }
      }

      usersList.forEach(data=>{
        if(searchQuery.value=='')
        {
          if(index>=startIndex && index<=endIndex)
          {
            users.push({
                'field_name':data.fieldName,
                'id':data.id,
                'language':data.language,
                'remark':data.remark,
                'course_name':data.courseName,
                'mode_of_application':data.modeOfApplication,
                'intake':data.intake,
                'application_deadline':data.applicationDeadline,
                'german_language':data.germanLanguage,
                'exams':data.exams,
                'band_scores':data.exams,
                'applicationFees':data.applicationFees||'',
                'tutionFees':data.tutionFees||'',
                'courseUniqueId':data.courseUniqueId||'',
                'isDeleted':data.isDeleted ||false
            })
            // dataIndex++;
          }
          if(index==usersList.length)
          {
            // if(currentPage.value > 1 && users.length==0)
            // {
            //   currentPage.value -= currentPage.value
            // }
            totalUsers.value = usersList.length
            isSpinner.value = false
            callback(users)
          }
          index++;
        }
        else if(searchQuery.value!='')
        {
          if(
            data.toLowerCase().includes(searchQuery.value.toLowerCase())
          )
          {
            users.push({
                'field_name':data.fieldName,
                'id':data.id,
                'course_name':data.courseName,
                'language':data.language,
                'remark':data.remark,
                'mode_of_application':data.modeOfApplication,
                'intake':data.intake,
                'application_deadline':data.applicationDeadline,
                'german_language':data.germanLanguage,
                'exams':data.exams,
                'band_scores':data.exams,
                'applicationFees':data.applicationFees||'',
                'tutionFees':data.tutionFees||'',
                'courseUniqueId':data.courseUniqueId||'',
                'isDeleted':data.isDeleted ||false
            })
          }
          if(index==usersList.length)
          {
            // if(currentPage.value > 1 && users.length==0)
            // {
            //   currentPage.value -= currentPage.value
            // }
            totalUsers.value = usersList.length
            isSpinner.value = false
            callback(users)
          }
          index++;
        }
        else
        {
          isSpinner.value = false
        }
      })
    }

    // for (let index = 0; index < 10; index++) {
    //   users.push({
    //     'No':index,
    //     'Date of Inquiry':`Date ${index}`,
    //     'Student Name':`Name ${index}`,
    //     'Phone no.':9462489464654,
    //     'College Name.':`Test`,
    //     'Branch.':`abcd`,
    //     'CGPA(%).':(index+1*(Math.random()*100)%100).toFixed(2),
    //     'actions':`<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create Account </b-button> <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create New Inquiry </b-button>`
    //   })
    // }
    // callback(users)
  }

  // const handlePagination(dataObj){
  //   console.log(dataObj)
  // }

  const refreshTable = (cb)=>{
    refetchData()
    cb(true)
  }
  const addNewCourse = async(courseArr, callback) => {
      if(currentPage.value > 1) {
        currentPage.value = 1;
        if(courseArr.length < perPage.value) {
          await fetchCourseFromTypesense(false,perPage.value-courseArr.length,()=>{
            const startIndex = (currentPage.value - 1) * perPage.value;
      
            const valueForIncrement = Math.min(courseArr.length, perPage.value);
      
            for (let i = 0; i < valueForIncrement; i++) {
                const data = courseArr[i];
                fetchedData.value.splice(startIndex + i, 0, {
                    ...data,
                    'application_deadline': data.applicationDeadline,
                    'course_name': data.courseName.title,
                    'field_name': data.fieldName,
                    'mode_of_application': data.modeOfApplication,
                    'band_scores': data.exams,
                    'german_language': data.germanLanguage
                });
            }
            if(fetchedData.value.length > perPage.value) {
              fetchedData.value.splice(perPage.value)
            }
            totalUsers.value += valueForIncrement;
            coursesTableList.value = fetchedData.value;
            refetchData();
            callback(true, 'success');
          })
        }
      } else {
        const startIndex = (currentPage.value - 1) * perPage.value;
        const itemsToAdd = Math.min(courseArr.length, perPage.value);
        for (let i = 0; i < itemsToAdd; i++) {
            const data = courseArr[i];
            fetchedData.value.splice(startIndex + i, 0, {
                ...data,
                'application_deadline': data.applicationDeadline,
                'course_name': data.courseName.title,
                'field_name': data.fieldName,
                'mode_of_application': data.modeOfApplication,
                'band_scores': data.exams,
                'german_language': data.germanLanguage
            });
        }
        if(fetchedData.value.length > perPage.value) {
          fetchedData.value.splice(perPage.value)
        }
        totalUsers.value += courseArr.length>perPage.value ? courseArr.length : itemsToAdd;
        coursesTableList.value = fetchedData.value;
        refetchData();
        callback(true, 'success');
      }
  }

  const editCourse = (data, callback)=>{    
    var index = (currentPage.value-1) * perPage.value + data.index + 1
    // console.log(usersList,'usersList');
    // usersList[index-1]={
    //   ...data,
    //   'fieldName':data.oldObject.field_name,
    //   'courseName':data.oldObject.course_name,
    //   'language':data.language || null,
    //   'modeOfApplication':data.modeOfApplication,
    //   'intake':data.intake,
    //   'applicationDeadline':data.applicationDeadline,
    //   'germanLanguage':data.germanLanguage,
    //   'exams':data.exams,
    //   'remark':data.remark,
    //   'applicationFees':data.applicationFees,
    //   'tutionFees':data.tutionFees,
    //   'courseUniqueId':data.courseUniqueId,
    //   'application_deadline':data.applicationDeadline,
    //   'course_name':data.courseName.title,
    //   'field_name':data.fieldName.name,
    //   'mode_of_application':data.modeOfApplication,
    //   'band_scores':data.exams,
    //   'german_language':data.germanLanguage
    // }
    // let array = JSON.parse(JSON.stringify(usersList));
    // let keysToRemove = ['application_deadline', 'german_language', 'band_scores', 'mode_of_application', 'field_name', 'course_name'];

    // array.forEach(obj => {
    //   console.log(obj);
    //   keysToRemove.forEach(key => delete obj[key]);
    // });
    let UpdateObject = {
      'fieldName':data.oldObject.field_name,
      'courseName':data.oldObject.course_name,
      'language':data.language || null,
      'modeOfApplication':data.modeOfApplication,
      'intake':data.intake,
      'applicationDeadline':data.applicationDeadline,
      'germanLanguage':data.germanLanguage,
      'exams':data.exams,
      'remark':data.remark,
      'applicationFees':data.applicationFees,
      'tutionFees':data.tutionFees,
      'courseUniqueId':data.courseUniqueId,
    }

    db
      .collection(dbCollections.COURSES)
      .doc(data.id)
      .update({
        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
        ...UpdateObject
      })
      .then(()=>{
        // console.log('FIREBASE UPDATED SUCCESSFUL STEP 1');
        // updateTypesenseCollection(dbCollections.COURSES,UpdateObject,data.id).then(()=>{
          // console.log('Typesense UPDATED SUCCESSFUL STEP 2');
          // console.log(usersList)
          let indexUpdate = fetchedData.value.findIndex((e)=>e.id==data.id)
          let updateObject = {
              'fieldName':data.oldObject.field_name,
              'courseName':data.oldObject.course_name,
              'language':data.language || null,
              'modeOfApplication':data.modeOfApplication,
              'intake':data.intake,
              'applicationDeadline':data.applicationDeadline,
              'germanLanguage':data.germanLanguage,
              'exams':data.exams,
              'remark':data.remark,
              'applicationFees':data.applicationFees,
              'tutionFees':data.tutionFees,
              'courseUniqueId':data.courseUniqueId,
              'application_deadline':data.applicationDeadline,
              'course_name':data.courseName.title,
              'field_name':data.fieldName,
              'mode_of_application':data.modeOfApplication,
              'band_scores':data.exams,
              'german_language':data.germanLanguage
          }
          if(indexUpdate !== -1){
            fetchedData.value[indexUpdate] = {...fetchedData.value[indexUpdate],...updateObject}
          }
          refetchData()
          callback(true,'success')
        // }).catch(error =>{
        //   console.log(error)
        //   callback(false,error.message)
        // })
      })
      .catch(error =>{
        console.log(error)
        callback(false,error.message)
      })
  }

  function deleteCollegeCourse(applicationData,fieldItem,cb){
    // console.log(applicationData,fieldItem)
    // console.log(collegeDocId.value,"collegeDocId.value")
    let suggestedCollege = applicationData.selectedCollege;
    if(suggestedCollege.length > 0){
      let searchIndex = suggestedCollege.findIndex(elem=>{
        return elem.courseUniqueId == fieldItem.courseUniqueId
      }) 
      if(searchIndex > -1){
        cb(true)
      }else{
         cb(false)
      }
    }else{
    cb(false)
    }
  }
  const deleteField = (ctx, callback)=>{
    const index = (currentPage.value - 1) * perPage.value + ctx.index;
  
    const toggleValue = ctx.item.isDeleted === undefined ? true : !ctx.item.isDeleted;
  
    // usersList[index].isDeleted = toggleValue;

    // db.collection(dbCollections.COLLEGES)
    //   .doc(collegeDocId.value)
    //   .update({
    //     updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
    //     courses: usersList
    //   })
    //   .then(() => {
        db.collection(dbCollections.COURSES).doc(ctx.item.id).update({isDeleted: toggleValue,updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp()}).then(()=>{
          // let objDatas = {isDeleted: toggleValue}
          // updateTypesenseCollection(dbCollections.COURSES,objDatas,ctx.item.id).then(()=>{
            let indexUpdate = fetchedData.value.findIndex((e)=>e.id==ctx.item.id)
            let updateObject = {
                'isDeleted':toggleValue
            }
            if(indexUpdate !== -1){
              fetchedData.value[indexUpdate] = {...fetchedData.value[indexUpdate],...updateObject}
              refetchData();
            }
            callback(true, 'success');
          // }).catch((error)=>{
          //   console.error(error)
          // })
        }).catch((error)=>{
          console.error(error)
        })
      // })
      // .catch(error => {
      //   callback(false, error.message);
      // });
    // var index = (currentPage.value-1) * perPage.value + ctx.index
    // console.log("deleteField",index,ctx.item.isDeleted)
    // if(ctx.item.isDeleted == true) {
    //   // usersList.splice(index,1)
    //   usersList[index].isDeleted = false
    //   db
    //     .collection(dbCollections.COLLEGES)
    //     .doc(collegeDocId.value)
    //     .update({
    //         updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(), 
    //         courses:usersList
    //     })
    //     .then(()=>{
    //       // console.log(usersList)
    //       refetchData()
    //       callback(true,'success')
    //     })
    //     .catch(error=>{
    //       callback(false,error.message)
    //       console.log(error)
    //     })
    // } else if(ctx.item.isDeleted == true) {
    //   usersList[index].isDeleted = true
    //   db
    //     .collection(dbCollections.COLLEGES)
    //     .doc(collegeDocId.value)
    //     .update({
    //         updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(), 
    //         courses:usersList
    //     })
    //     .then(()=>{
    //       // console.log(usersList)
    //       refetchData()
    //       callback(true,'success')
    //     })
    //     .catch(error=>{
    //       callback(false,error.message)
    //       console.log(error)
    //     })
    // } else {
    //   usersList[index].isDeleted = true
    //   db
    //     .collection(dbCollections.COLLEGES)
    //     .doc(collegeDocId.value)
    //     .update({
    //         updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(), 
    //         courses:usersList
    //     })
    //     .then(()=>{
    //       // console.log(usersList)
    //       refetchData()
    //       callback(true,'success')
    //     })
    //     .catch(error=>{
    //       callback(false,error.message)
    //       console.log(error)
    //     })
    // }
    // db
    // .collection(dbCollections.APPLICATIONS)
    // .orderBy('createdAt')
    // .get()
    // .then(querySnapshot=>{
    //   let fileArry = [];
    //   if(!querySnapshot.empty)
    //   {
    //         querySnapshot.forEach(val=>{
    //           if(val.isDeleted !== true){
    //           fileArry.push(val.data());
    //           }
    //         })
    //         let count = 0;    
    //         // console.log(fileArry,"fileArry")      
    //         const uploadFileFunction = (row)=> {
    //           if(count >= fileArry.length){
    //             usersList.splice(index,1)
    //             db
    //               .collection(dbCollections.COLLEGES)
    //               .doc(collegeDocId.value)
    //               .update({
    //                   updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(), 
    //                   courses:usersList
    //               })
    //               .then(()=>{
    //                 // console.log(usersList)
    //                 refetchData()
    //                 callback(true,'success')
    //               })
    //               .catch(error=>{
    //                 callback(false,error.message)
    //                 console.log(error)
    //               })    
    //               return;            
    //           }else{
    //           deleteCollegeCourse(row,ctx.item,(saveRes)=>{
    //               if(saveRes == true){
    //                 // console.log(saveRes,"saveRes")
    //                 callback(false,'restriction')
    //                 // callback({'msg':'You can not delete field which used by colleges.',opt:false})
    //                 return;
    //               }
    //               if(saveRes == false){
    //               count += 1;
    //               uploadFileFunction(fileArry[count]);
    //               }
    //           });
    //         }              
    //       }
    //       uploadFileFunction(fileArry[count]);
    //   }
    //   else{
    //     usersList.splice(index,1)
    //     db
    //       .collection(dbCollections.COLLEGES)
    //       .doc(collegeDocId.value)
    //       .update({
    //           updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(), 
    //           courses:usersList
    //       })
    //       .then(()=>{
    //         // console.log(usersList)
    //         refetchData()
    //         callback(true,'success')
    //       })
    //       .catch(error=>{
    //         callback(false,error.message)
    //         console.log(error)
    //       })
    //   }
    // })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    fetchCourseFromTypesense,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    deleteField,
    addNewCourse,
    editCourse,

    collegeDocId,
    coursesTableList,

    isSpinner,
    refreshTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    filterCountry,
    filterState,
    filterCity,

    getfieldAndcourseData,
    handlePageChange,
    handleSortChange,
    isFetching,
    fetchedData
  }
}
